import React, {useCallback, useEffect, useState} from "react";
import {IPatientInCall} from "../../models";
import {canEditAndMakeCallsToPatient} from "../clinical-notes/accessControl";
import {PageButtonV2} from "../generic/Buttons";
import {ACTIVE_CALL_STATUSES, requestCall} from "../../store/actions/counsellor/calls.action";
import {useDispatch, useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";
import PhoneIcon from "../../assets/images/calls/phone.svg"
import {fetchPracticeDetails} from "../../store/actions/counsellor/practice.action";
import { Tooltip } from 'react-tooltip'
import CallModalComponent from "../../screens/prospects/care-coordinator/CallModalComponent";
import { fetchPatientLocation } from "../../store/actions/counsellor/patients.action";
import _ from "lodash";

interface Props {
    patient: IPatientInCall
    sourcePatientId: string
    onCallInitated?: () => void
}

const CallPatientComponent: React.FC<Props> = ({patient, sourcePatientId, onCallInitated}) => {
    const callStatus = useSelector((state: StateParams) => state.calls.callStatus)
    const role = useSelector((state: StateParams) => state.account.role)
    const {id : practiceId, isPhoneVerified} = useSelector((state: StateParams) => state.practice)
    const {id: physicianPracticeId} = useSelector((state: StateParams) => state.physicianAccount)
    const patientLocation = useSelector((state: StateParams) => state.patients.patientLocation)
    const [validToMakeCall, setValidToMakeCall] = useState<boolean | undefined>(undefined)
    const [isLocationPhoneVerified, setIsLocationPhoneVerified] = useState<boolean | undefined>(undefined)
    const [isPatientPhoneNumberValid, setIsPatientPhoneNumberValid] = useState<boolean | undefined>(undefined)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const dispatch = useDispatch()

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    useEffect(() => {
        dispatch(fetchPracticeDetails({practiceId: patient.referredByPracticeId}))
        dispatch(fetchPatientLocation(sourcePatientId))
    }, [])


    useEffect(() => {
        if((role !== 'Physician' && patient.referredByPracticeId === practiceId) || (role === 'Physician' && patient.referredByPracticeId === physicianPracticeId)) {
            setIsLocationPhoneVerified(patientLocation?.isPhoneVerified === true)
        }
    }, [patient, practiceId, patientLocation?.isPhoneVerified])

    useEffect(() => {
        console.log("CallPatientComponent.tsx :: useEffect :: patient :: ", patient);
        const US_FORMATTED_NUMBER_REGEX = /\+1\d{10}$/;
        const IN_FORMATTED_NUMBER_REGEX = /\+91\d{10}$/;
        if (patient && patient?.phoneNumber && (patient?.phoneNumber?.match(US_FORMATTED_NUMBER_REGEX) || patient?.phoneNumber?.match(IN_FORMATTED_NUMBER_REGEX))) {
            setIsPatientPhoneNumberValid(true);
        } else {
            setIsPatientPhoneNumberValid(false);
        }
    }, [patient]);

    useEffect(() => {
        if(isLocationPhoneVerified && isPatientPhoneNumberValid) {
            setValidToMakeCall(true);
        } else {
            setValidToMakeCall(false);
        }
    }, [isLocationPhoneVerified, isPatientPhoneNumberValid]);

    function renderToolTipForInvalidPhoneNumber() {
        return <Tooltip style={{borderRadius: 5, zIndex: 9999}} id="phone-number-tooltip" >
            <div className={"w-[200px]"}>
                {
                    !isLocationPhoneVerified && <span>
                        The practice phone number is not verified.
                    </span>
                }
                {
                    (isLocationPhoneVerified && !isPatientPhoneNumberValid) && <span>
                        The patient phone number is invalid. Please update the phone number.
                    </span>
                }
            </div>
        </Tooltip>
    }

    function renderEditAndCallButtons() {
        return (
            <div className={"mb-2"}>
                {canEditAndMakeCallsToPatient(role) &&
                    <PageButtonV2
                        className={`${ACTIVE_CALL_STATUSES.includes(callStatus) || validToMakeCall === false ? "!bg-sjGrey" : "!bg-sjOrange"} !text-white !ml-2 px-[16px] py-[8px] !h-8 text-sm`}
                        disabled={ACTIVE_CALL_STATUSES.includes(callStatus) || validToMakeCall === false}
                        data-tooltip-id="phone-number-tooltip"
                        onClick={() => setIsModalOpen(true)}
                    >

                        <div className={"flex flex-row gap-x-2 items-center"}>
                            <span>Call Patient</span>
                            <img src={PhoneIcon} width={14} height={14} className={"hover:cursor-pointer"}/>
                        </div>

                    </PageButtonV2>
                }
                {!validToMakeCall && renderToolTipForInvalidPhoneNumber()}
                <CallModalComponent isModalOpen={isModalOpen} handleCloseModal={handleCloseModal} prospectDetails={patient}/>
            </div>
        )
    }

    return <>
        <div className={"flex flex-col"}>
            { renderEditAndCallButtons() }
        </div>
    </>

}

export default CallPatientComponent
