interface Options {
    value: string
    label: string | null
}
interface Props {
    options: Options[]
    title: string
    onChange: (e: any) => void
}

export function Dropdown({
    title, 
    options,
    onChange
}: Props) {
    return (
        <div>
            <label className="text-sjLabel text-sm  ">{title}</label>
            <div className="mt-2">
                <select onChange={onChange}
                    className="w-full text-sm border rounded pl-3 pr-8 py-2 appearance-none cursor-pointer">
                    {options.map((option, index) => <option key={index} className="tracking-wider" value={option.value}>{option.label}</option>)}
                </select>
                {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.2" stroke="currentColor" className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                </svg> */}
            </div>
        </div>
    )
}